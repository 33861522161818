/*global $*/
/*eslint no-undef: "error"*/
export default class BlockSection {
    classButtons = 'block-section--buttons'
    classButton = 'block-section--button'
    classButtonActive = 'block-tab__button---active'

    classBlocks = 'block-section--blocks'
    classBlock = 'block-section--block'
    classBlocksActive = 'block-section__blocks---active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classButtons}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(`.${self.classBlocks}`).find(`.${self.classBlock}`).removeClass(self.classBlocksActive)
            $(`.${self.classBlocks}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlocksActive)
        })
    }
}
