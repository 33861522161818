import { createStore } from 'vuex'
import md5 from 'js-md5'

export default createStore({
    state: {
        basePath: location.protocol + '//' + location.hostname,
        send: false,
        sendSuccess: false
    },
    mutations: {
        changeSuccess (state, payload) {
            state.sendSuccess = payload
        }
    },
    actions: {
        async Send (context, payload) {
            let hashStr = parseInt(payload.setting.number)
            payload.fields.forEach(item => {
                if (item.value) {
                    hashStr += parseInt(item.value.length)
                }
            })

            if ((process.env.NODE_ENV === 'development')) {
                console.log(payload)
            } else {
                const fields = {
                    title: payload.setting.title,
                    subject: payload.setting.subject,
                    number: payload.setting.number,
                    line: md5(hashStr + '53N8StsA'),
                    fields: payload.fields
                }

                const formData = new FormData()
                formData.append('fields', JSON.stringify(fields))

                if (payload.files !== '') {
                    for (let i = 0; i < payload.files.length; i++) {
                        let file = payload.files[i]
                        formData.append('files[' + i + ']', file)
                    }
                }

                const response = await fetch(`/api/v1/send_form/`, {
                    method: 'POST',
                    body: formData
                })

                const result = await response.json()

                if (result.send) {
                    setTimeout(() => {
                        window.location.href = window.ritcentr.form.redirect
                    }, 600)
                }
            }
        }
    },
    modules: {
    },
    getters: {
        isSend: s => s.send,
        sendSuccess: s => s.sendSuccess
    }
})
