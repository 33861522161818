<template lang="pug">
.form-popup.form-popup-100
  .h3.center(v-html="settings.title")
  .form-popup__fields
    .form-popup__field
      .input-text
        input.input-text__input(
          type="text"
          placeholder="Ваше имя *"
          v-model="fieldName"
          :class="{'input-text__input-error': v$.fieldName.$invalid && (fieldName.length > 0), 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
        )
    .form-popup__field
      .input-text
        input.input-text__input(
          type="tel"
          placeholder="Ваш телефон *"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          v-model="fieldPhone"
          :class="{'input-text__input-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0), 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
        )

  .consent
    input.consent__input(type="checkbox" id="form-checkbox-1" v-model="consent" value="consent" checked="checked")
    label.consent__label(for="form-checkbox-1" v-html="settingConsent.text")

  .form-popup__button
    .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
      .button__name(v-html="settings.button")
</template>

<script>
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormMain',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      settingConsent: {},
      files: '',
      consent: ['consent'],
      fieldName: '',
      fieldPhone: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.Send({
          setting: {
            number: '2',
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Основная форма"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.fieldName
            },
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'Id страницы',
              value: this.settings.fields.id
            },
            {
              title: 'Название страницы',
              value: this.settings.fields.pageName
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.settings = window.ritcentr.FormMain
    this.settingConsent = window.ritcentr.FormPopupConsent
  }
}
</script>
