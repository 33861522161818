/*global $*/
/*eslint no-undef: "error"*/
export default class ScrollBlock {
    classButton = 'scroll-block--button'
    classHeader = 'page-header'

    constructor () {
        let self = this
        let anchor = window.location.hash.substring(1)

        if (anchor) {
            let objButton = $(`.${self.classButton}`)

            if ($(`#${anchor}`).length) {
                objButton.click(function () {
                    $(`html, body`).animate({
                        scrollTop: $(`#${anchor}`).offset().top - $(`.${self.classHeader}`).height() - 5
                    }, 800);
                })

                objButton.click()
            }
        }
    }
}
