/*global $*/
/*eslint no-undef: "error"*/

import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSliderReviewClient () {
        return new Swiper('#slider--review-client', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-client-next',
                prevEl: '.slider--review-client-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 3
                }
            }
        })
    }
    createSliderReview () {
        return new Swiper('#slider--review', {
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {}
        })
    }
    createSliderProductsMore () {
        return new Swiper('#slider--products-more', {
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-client-next',
                prevEl: '.slider--review-client-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }
    createSliderCemeterie () {
        return new Swiper('#slider--cemeterie', {
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--cemeterie-next',
                prevEl: '.slider--cemeterie-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }
    createSliderSlider () {
        return new Swiper('#slider--slider', {
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: {
                delay: 6500
            },
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {}
        })
    }
    createSliderHall () {
        return new Swiper('.slider--hall', {
            slidesPerView: 1,
            spaceBetween: 5,
            loop: true,
            navigation: {
                nextEl: '.slider--hall-next',
                prevEl: '.slider--hall-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {}
        })
    }
    createSliderTop () {
        return new Swiper('#slider--top', {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: false,
            navigation: {
                nextEl: '.slider--top-next',
                prevEl: '.slider--top-prev',
            },
            pagination: {
                el: '.swiper-pagination--top',
                clickable: true
            },
            breakpoints: {}
        })
    }
    createSliderService () {
        return new Swiper('#slider--service', {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlides: false,
            navigation: {
                nextEl: '.slider--service-next',
                prevEl: '.slider--service-prev',
            },
            pagination: {
                el: '.swiper-pagination--service',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1.75,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 1.75,
                    spaceBetween: 20
                },
                991: {
                    slidesPerView: 1.75,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 20
                },
                1240: {
                    slidesPerView: 2.5,
                    spaceBetween: 20
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--review-client')!= null) {
            this.createSliderReviewClient()
        }

        if(document.getElementById('slider--review')!= null) {
            this.createSliderReview()
        }

        if(document.getElementById('slider--products-more')!= null) {
            this.createSliderProductsMore()
        }

        if(document.getElementById('slider--cemeterie')!= null) {
            this.createSliderCemeterie()
        }

        if(document.getElementById('slider--slider')!= null) {
            this.createSliderSlider()
        }

        if (parseInt($('.slider--hall').length) > 0) {
            this.createSliderHall()
        }

        if(document.getElementById('slider--top')!= null) {
            this.createSliderTop()
        }

        if(document.getElementById('slider--service')!= null) {
            this.createSliderService()
        }
    }
}
